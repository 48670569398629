import {
  ActiveExamDTO,
  AvailableExamDTO,
  ExamAdminInfoDTO,
  ExamAssessmentSectionQuestions,
  ExamDTO,
  ExamQuestionsForId,
  ExamSubjectDTO,
  ExamTypeDTO,
  IAudited,
  IndividualReportDTO,
  ResultsDTO,
  RetakeLimitDTO,
} from 'recertify';
import axios from 'axios';
import { saveAs } from 'file-saver';
import Axios from '../utils/http.config';
import { UserService } from './UserService';

const BASE_URL = `/exam`;

export class ExamService {
  public static getListOfAllExamsForType(id: number): Promise<ExamSubjectDTO[]> {
    return Axios.get<{ data: { exams: ExamSubjectDTO[] } }>(`admin/exam/list/${id}`)
      .then(response => response.data.data.exams);
  }

  public static getCustomRetakeLimit(retakeLimit: RetakeLimitDTO): Promise<RetakeLimitDTO> {
    return Axios.post<{ data: RetakeLimitDTO }>(`admin/exam/getCustomRetakeLimit`,
      retakeLimit, { headers: { 'ims-token': UserService.getToken() } })
      .then(response => response.data.data);
  }

  public static getAvailableExamCountForExamType(examTypeId: number): Promise<number> {
    return Axios.get<{ data: { results: number } }>(`exam/enabledCount/${examTypeId}`)
      .then(response => response.data.data.results);
  }

  public static setCustomRetakeLimit(retakeLimit: RetakeLimitDTO): Promise<void> {
    return Axios.post(`admin/exam/setCustomRetakeLimit`,
      retakeLimit, { headers: { 'ims-token': UserService.getToken() } })
      .then(response => {
        if (response.status !== 200) {
          throw new Error(`Failed to set custom retake limit`);
        }
      });
  }

  public static removeCustomRetakeLimit(retakeLimit: RetakeLimitDTO): Promise<void> {
    return Axios.post(`admin/exam/removeCustomRetakeLimit`,
      retakeLimit, { headers: { 'ims-token': UserService.getToken() } })
      .then(response => {
        if (response.status !== 200) {
          throw new Error(`Failed to remove custom retake limit`);
        }
      });
  }

  public static getListOfAllExamTypes(): Promise<ExamTypeDTO[]> {
    return Axios.get<{data: { examTypes: ExamTypeDTO[]}}>(`admin/exam/list`)
      .then(response => response.data.data.examTypes);
  }

  public static search(): Promise<ResultsDTO[]> {
    return Axios.get<{ data: ResultsDTO[] }>(`${BASE_URL}/complete`)
      .then(response => response.data.data);
  }

  public static getActiveExams(): Promise<ActiveExamDTO[]> {
    return Axios.get<{data: { exams: ActiveExamDTO[]}}>(`exam/active`, {
    })
      .then(response => response.data.data.exams);
  }

  public static getUserSessions(): Promise<AvailableExamDTO[]> {
    return Axios.get<{ data: { availableOpenSessions: AvailableExamDTO[]}}>(`session/open`, {
    })
      .then(response => response.data.data.availableOpenSessions);
  }

  public static downloadDocument(documentId: number): Promise<void> {
    return Axios.get<Blob>(`download/document/${documentId}`, {
      responseType: `blob`,
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([ response.data ]));
        saveAs(url, `manual_${documentId}.pdf`);
        window.URL.revokeObjectURL(url);
      });
  }

  public static getIndividualReport(examId: number): Promise<IndividualReportDTO> {
    return Axios.get<{ data: IAudited<IndividualReportDTO> }>(`${BASE_URL}/review/${examId}`)
      .then(response => response.data.data);
  }

  public static getExamAdminInfo(examId: number): Promise<ExamAdminInfoDTO> {
    return Axios.get<{ data: ExamAdminInfoDTO }>(`/admin${BASE_URL}/${examId}/edit`)
      .then(response => response.data.data);
  }

  public static getQuestionsForExamId(examId: number): Promise<ExamQuestionsForId[]> {
    return Axios.get<{ data: ExamQuestionsForId[] }>(`/admin${BASE_URL}/${examId}/questions`)
      .then(response => response.data.data);
  }

  public static updateExam(examId: number, answerId: number) {
    return Axios.post(`/admin${BASE_URL}/${examId}/answer/${answerId}`)
      .then(response => response);
  }

  public static updateExamParameters(formData: FormData): Promise<number> {
    return Axios.put<{ data: { examId: number } }>(`/admin${BASE_URL}/update`, formData)
      .then(response => response.data.data.examId);
  }

  public static getProgress(examInstanceId: number) {
    return Axios.get<{ data: { progress: number } }>(`examsitting/${Number(examInstanceId)}/progress/total`)
      .then(response => response.data.data.progress);
  }

  public static getVideoProgress(examInstanceId: number, videoType: `ppt` | `interview`) {
    if (![ `ppt`, `interview` ].includes(videoType)) {
      throw new Error(`Unknown video type`);
    }

    return Axios.get<{ data: { progress: number } }>(`examsitting/${Number(examInstanceId)}/${videoType}/progress`)
      .then(response => response.data.data.progress);
  }

  public static getPptUrlAndTime(examInstanceId: number) {
    return Axios.get<{
      data: {
        time: number;
        videoId: number;
        videoHash: string;
      };
    }>(`examsitting/${Number(examInstanceId)}/ppt`)
      .then(response => response.data.data);
  }

  public static updatePptProgress(examInstanceId: number, percentage: number) {
    return Axios.post<{
      data: {
        progress: number;
      };
    }>(`examsitting/${Number(examInstanceId)}/ppt/progress`, {
      percent: percentage,
    })
      .then(response => response.data.data?.progress);
  }

  public static async updatePptTime(examInstanceId: number, time: number) {
    await Axios.post(`examsitting/${Number(examInstanceId)}/ppt/time`, { time });
  }

  public static getInterviewUrlTimeAndDocument(examInstanceId: number) {
    return Axios.get<{
      data: {
        time: number | null;
        videoId: number;
        videoHash: string;
        helpDocument: number | null;
      };
    }>(`examsitting/${Number(examInstanceId)}/interview`)
      .then(response => response.data.data);
  }

  public static updateInterviewProgress(examInstanceId: number, percentage: number) {
    return Axios.post<{
      data: {
        progress: number;
      };
    }>(`examsitting/${Number(examInstanceId)}/interview/progress`, {
      percent: percentage,
    })
      .then(response => response.data.data?.progress);
  }

  public static async updateInterviewTime(examInstanceId: number, time: number) {
    await Axios.post(`examsitting/${Number(examInstanceId)}/interview/time`, { time });
  }

  public static async getInfo(examInstanceId: number) {
    return Axios.get<{
      data: { exam_id: number, name: string, expiration_time: string };
    }>(`examsitting/${Number(examInstanceId)}/examtype`)
      .then(response => response.data.data);
  }

  public static async canGoToInterview(examInstanceId: number) {
    return Axios.get<{ data: { name: string } }>(`examsitting/${Number(examInstanceId)}/view/interview`)
      .then(response => {
        if (response.status !== 200) { throw new Error(`Unable to view Interview`); }

        return true;
      });
  }

  public static async canGoToAssessment(examInstanceId: number) {
    return Axios.get<{ data: { name: string } }>(`examsitting/${Number(examInstanceId)}/view/assessment`)
      .then(response => {
        if (response.status !== 200) { throw new Error(`Unable to go to Assessment`); }

        return true;
      });
  }

  public static getExamAssessmentQuestions(examId: number): Promise<ExamAssessmentSectionQuestions[]> {
    return Axios.get<{ data: ExamAssessmentSectionQuestions[] }>(`examsitting/${examId}/questions`)
      .then(response => response.data.data);
  }

  public static async setExamAssessmentQuestionResponse(
    examId: number,
    responseId: number,
  ) {
    return await Axios.post<{ data: ExamAssessmentSectionQuestions[] }>(`examsitting/${examId}/answer/${responseId}`)
      .then(response => response.data.data);
  }

  public static submitExam(
    examId: number,
  ): Promise<boolean> {
    return Axios.post<{ data: { is_passed: boolean } }>(`examsitting/${examId}/submit`)
      .then((response) => {
        if (response.status !== 200) { throw new Error(`Failed to submit assessment`); }
        return response.data.data.is_passed;
      });
  }

  public static async getPermissibleExamsForUser(examTypeId: number) {
    return Axios.get<{ data: { results: ExamDTO[] } }>(`examsitting/permissible/${examTypeId}`)
      .then(response => response.data.data.results);
  }

  public static async startNew(
    examTypeId: number,
    isSession: boolean,
    userExamInstanceId: number,
    sessionId: number,
  ) {
    return await Axios.post<{ data: { examSittingId: number }}>(`examsitting/new`, {
      examTypeId,
      isSession,
      sessionId,
      userExamInstanceId,
    })
      .then((response) => response.data.data.examSittingId)
      .catch((error) => {
        if (axios.isAxiosError<{ error: string }>(error) && error.response?.data) {
          throw new Error(error.response.data.error);
        }

        throw new Error(`Could not start exam`);
      });
  }

  public static async publishExam(
    data: FormData,
  ) {
    return await Axios.post(`/admin/exam/create`, data, {
      headers: {
        'Content-Type': `multipart/form-data`,
        'ims-token': UserService.getToken(),
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(`Failed to publish exam`);
        }
      });
  }

  public static exportUserResultsCompletedExams = (): Promise<Blob> =>
    Axios.post(`${BASE_URL}/complete/export`, {}, {
      responseType: `blob`,
    }).then((response) => response.data as Blob);

  public static getAgenciesCanGoBack = (agencyId: number): Promise<boolean> =>
    Axios.get< {data: {response: boolean}} >(`exam/agenciesCanGoBack/${agencyId}`)
      .then((response) => response.data.data.response);

  public static getUsersCantLeave = (agencyId: number): Promise<boolean> =>
    Axios.get< {data: {response: boolean}}>(`exam/usersCantLeave/${agencyId}`)
      .then((response) => response.data.data.response);

}
